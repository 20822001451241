import React from "react"

import { PlaceholderProvider } from '../components/placeholder'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"
import Video from "../components/video/video"
import bgVideo from '../components/video/video-bowl.mp4'
import videoPoster from "../components/video/video-poster-bowl.jpg"
import slogan from "../images/hashtag-nfluk.svg"

const Bowl = () => (
  <PlaceholderProvider value={`nfl`}>
    <Layout>
      <SEO title="Bowl" />
      <div className="cardsContainer cardsContainer--bowl cardsContainer--nfl">
          <Cards postCount={2} hero={false} />
          <Video src={bgVideo} poster={videoPoster} />
          <img className="slogan" src={slogan} alt="#NFLUK" />
      </div>
    </Layout>
  </PlaceholderProvider>
)

export default Bowl
